import React, { memo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { customPropTypes } from '../../util';

import './ZigZag.scss';

import { useCSSMotion, useSectionTimeSpent } from '../../util';

import ZigZagItem from './ZigZagItem/ZigZagItem';
import { graphql } from 'gatsby';

function ZigZag({ data: { title, listItems, name, id } }) {
  const titleRef = useRef(null);
  const containerRef = useRef(null);
  const titleMotionState = useCSSMotion(titleRef);

  useSectionTimeSpent(name, containerRef);

  return (
    <section id={id} className="ZigZag" ref={containerRef}>
      <h2 ref={titleRef} className={classnames('title', titleMotionState)}>
        {title}
      </h2>
      <div className="zig-zag-items-container">
        {listItems.map((data) => (
          <ZigZagItem key={data.title || data.description} data={data} />
        ))}
      </div>
    </section>
  );
}

ZigZag.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    listItems: PropTypes.arrayOf(ZigZagItem.type.propTypes.data),
  }).isRequired,
});

ZigZag.defaultProps = {};

export default memo(ZigZag);

export const fragments = graphql`
  fragment ZigZag on WpPage_Flexiblelayout_FlexibleChildren_Zigzag {
    title
    listItems {
      title
      description
      fieldGroupName
      icon {
        ...Icon
      }
    }
    id
    jumpToLinkTitle
    name
  }

  fragment ZigZag_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_Zigzag {
    title
    listItems {
      title
      description
      fieldGroupName
      icon {
        ...Icon
      }
    }
    id
    jumpToLinkTitle
    name
  }
`;
