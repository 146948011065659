import React, { memo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './ZigZagItem.scss';

import GatsbyImage from 'gatsby-image';
import { customPropTypes, useCSSMotion } from '../../../util';

function ZigZagItem({ data: { icon, title, description } }) {
  const containerRef = useRef(null);
  const motionState = useCSSMotion(containerRef);
  return (
    <div className={classnames('ZigZagItem', motionState)} ref={containerRef}>
      <GatsbyImage className="icon" fluid={icon?.localFile.childImageSharp.fluid} alt={icon?.altText} />
      {/*<img className="icon" src={icon.image.default.url} alt={icon.image.default.alt} />*/}
      <div className="content">
        <h3 className="title">{title}</h3>
        <p className="description">{description}</p>
      </div>
    </div>
  );
}

ZigZagItem.propTypes = checkExtraProps({
  data: PropTypes.shape({
    icon: customPropTypes.image,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
});

ZigZagItem.defaultProps = {};

export default memo(ZigZagItem);
